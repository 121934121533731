import { loadSlider } from '../shared/utils/swiper';

// This should correspond to the Schema of sections/featured-collection.liquid
const sectionSettingsExample = {
  title: 'Hobbii products',
  description: 'Collection description',
  show_description: false,
  collection: 'hobbii-yarn',
  show_view_all: true
};

type SectionSettings = typeof sectionSettingsExample;

const getSectionSettingsFromDOMElement = (el: HTMLElement): SectionSettings => {
  const { sectionSettings } = el.dataset;

  let settings: SectionSettings | null = null;

  if (!el.id)
    throw new Error('No id attribute assigned for featured collection section');

  if (!sectionSettings)
    throw new Error(
      `No settings found for featured collection section with id "${el.id}"`
    );

  settings = JSON.parse(sectionSettings);

  if (!settings || !settings.collection)
    throw new Error(
      `No collection configured for featured collection section with id "${el.id}"`
    );

  return settings;
};

const fetchCollectionSectionHtml = async (
  collectionHandle: string
): Promise<string> => {
  // Using the section rendering API to fetch the slider content
  // To get the context of the collection, we load the section using the collection's url
  // https://shopify.dev/docs/api/ajax/section-rendering
  const sectionLiquidFileName = 'collection-slider-async';
  const request = await fetch(
    `${window.Shopify.routes.root}collections/${collectionHandle}?sections=${sectionLiquidFileName}`
  );
  const response = await request.json();
  const section = response[sectionLiquidFileName];
  if (!section)
    throw new Error(
      `Section file "/sections/${sectionLiquidFileName}.liquid" not found in response.`
    );

  return section;
};

const loadCollections = async () => {
  const asyncCollectionEls = document.querySelectorAll<HTMLElement>(
    '.js-async-collection'
  );
  asyncCollectionEls.forEach(async (asyncCollectionEl) => {
    try {
      const settings = getSectionSettingsFromDOMElement(asyncCollectionEl);
      const section = await fetchCollectionSectionHtml(settings.collection);
      // Override section content (loading placeholder)
      asyncCollectionEl.innerHTML = section;

      // We use the section id from .js-async-collection to scope the slider initialization
      // to avoid initializing the slider multiple times
      loadSlider(`#${asyncCollectionEl.id} .js-collection-slider-async`, {});
    } catch (error) {
      // Fail silently
      console.error(error);
      return;
    }
  });
};

loadCollections();
